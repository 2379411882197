.root {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 7% 0 0 7%;
  height: 100vh;

  .banner {
    width: 60%;
    img {
      width: 100%;
    }
  }

  .mount {
    width: 35%;
    img {
      width: 100%;
    }
  }
}

.outlet {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 50px;
  width: 100%;
  padding: 40px;
}